import React from 'react';
import { Element } from 'react-scroll';
import about from 'assets/freePik/about.gif';

import './about.scss';

const About: React.FC = () => {
  return (
    <Element name='about'>
      <div className='default-class-about'>
        <div className='container'>
          <div className='content-img'>
            <img className='image' src={about} />
          </div>
          <div className='content-text'>
            <div className='title'>SOBRE NÓS</div>
            <div className='description'>
              Fundada em 2021 em Vitória da Conquista, Bahia, Intuitiva é uma empresa apaixonada por inovação
              industrial. A missão da Intuitiva é criar soluções industriais sob medida para resolver desafios
              específicos, otimizando processos e impulsionando a eficiência.
              <br />
              <br />
              Acreditamos na busca pela qualidade, na inovação contínua e em parcerias de longo prazo com nossos
              clientes. Estamos preparados para enfrentar os desafios em constante evolução da indústria, adaptando
              nossas soluções às necessidades cambiantes dos negócios.
              <br />
              <br />
              Junte-se à Intuitiva e descubra como nossa paixão pela inovação e nosso compromisso com a eficiência podem
              impulsionar o sucesso de sua empresa. Estamos ansiosos para ser seu parceiro na busca pela excelência
              industrial.
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default About;
