import axios from 'axios';

export function getApi(token?: string, baseURL = 'https://api.intuitiva.io/') {
  const api = axios.create({
    baseURL: baseURL,
    timeout: 15000,
  });

  api.interceptors.request.use(
    async (config) => {
      const apiConfig = config;
      if (token) {
        apiConfig.headers.Authorization = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return api;
}
