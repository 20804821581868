import React from 'react';
import { Element, Link } from 'react-scroll';
import ilustrador from 'assets/freePik/At the office-amico.png';
import waveBottom from 'assets/waves/wave-bottom.svg';

import { Button } from 'primereact/button';

import './home.scss';

const Home: React.FC = () => {
  return (
    <Element name='home'>
      <div className='container-home'>
        <div className='content'>
          <div className='header'>
            <div className='image' />
            <div className='options'>
              <Link to='home' spy={true} smooth={true} offset={0} duration={500}>
                Inicio
              </Link>
              <Link to='about' spy={true} smooth={true} offset={-200} duration={500}>
                Sobre
              </Link>
              <Link to='software' spy={true} smooth={true} offset={-200} duration={500}>
                Software
              </Link>
              <Link to='hardware' spy={true} smooth={true} offset={-100} duration={500}>
                Hardware
              </Link>
              <Link to='product' spy={true} smooth={true} offset={-100} duration={500}>
                Produtos
              </Link>
              <Link to='contact' spy={true} smooth={true} offset={-100} duration={500}>
                Contato
              </Link>
            </div>
          </div>
          <div className='container'>
            <div className='container-title'>
              <div className='title'>
                Maximize sua Eficiência com <span className='color-system'>Soluções Avançadas</span> de Monitoramento
                Industrial!
              </div>
              <div className='subtitle'>
                Melhore a eficiência da sua indústria com nosso Monitoramento Industrial avançado. Tenha controle total
                em tempo real, transformando dados em resultados mensuráveis. Aumente a produtividade, reduza custos e
                alcance um novo nível de eficiência com nossa solução. Descubra como podemos impulsionar seu sucesso na
                indústria.
              </div>
              <Link to='contact' spy={true} smooth={true} offset={-100} duration={500}>
                <Button className='button' label='SOLICITE UMA DEMONSTRAÇÃO' />
              </Link>
            </div>
            <div className='container-ilustrador'>
              <img className='image' src={ilustrador} />
            </div>
          </div>
        </div>
        <img src={waveBottom} className='image-wave-bottom-abs' />
      </div>
    </Element>
  );
};

export default Home;
