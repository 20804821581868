import React from 'react';
import { Element } from 'react-scroll';
import optimization from 'assets/freePik/Analytics-bro.png';
import cleanUp from 'assets/freePik/Inbox cleanup-pana.png';
import code from 'assets/freePik/Software code testing-pana.png';
import waveBottom from 'assets/waves/wave-bottom.svg';
import waveTop from 'assets/waves/wave-top.svg';

import './services.scss';

const Services: React.FC = () => {
  return (
    <Element name='product'>
      <div className='default-class-services'>
        <img src={waveTop} className='image-wave-top' />
        <div className='container'>
          <div className='content-title'>
            <div className='title'>NOSSOS PRODUTOS</div>
          </div>
          <div className='content-card'>
            <div className='card'>
              <div className='container-img'>
                <img src={optimization} className='image' />
              </div>
              <div className='title'>Eficácia de Equipamentos (OEE)</div>
              <div className='description'>
                Potencialize a eficiência de sua produção com nosso serviço de Eficácia Geral de Equipamentos (OEE).
                Analise e otimize o desempenho de seus equipamentos para maximizar a produtividade e reduzir custos.
              </div>
            </div>
            <div className='card'>
              <div className='container-img'>
                <img src={code} className='image' />
              </div>
              <div className='title'>Monitoramento de Vagas</div>
              <div className='description'>
                Otimize sua gestão de estacionamento com nosso serviço de Monitoramento de Vagas. Acompanhe em tempo
                real a disponibilidade de vagas, melhore a experiência dos clientes e aumente a eficiência da gestão de
                estacionamento.
              </div>
            </div>
            <div className='card'>
              <div className='container-img'>
                <img src={cleanUp} className='image' />
              </div>
              <div className='title'>Scrum Board</div>
              <div className='description'>
                Melhore a gestão de projetos e equipes com nosso serviço Scrum Board. Acompanhe tarefas, prioridades e
                prazos de forma eficiente, promovendo colaboração e entregas bem-sucedidas.
              </div>
            </div>
          </div>
        </div>
        <img src={waveBottom} className='image-wave-bottom' />
      </div>
    </Element>
  );
};

export default Services;
