import React from 'react';
import { Link } from 'react-scroll';
import waveTop from 'assets/waves/wave-top.svg';

import './footer.scss';

interface IProps {
  enableNavigation: boolean;
}

const Footer: React.FC<IProps> = ({ enableNavigation }) => {
  return (
    <div className='default-footer-class'>
      <img src={waveTop} className='image-wave-top' />
      <div className='container'>
        <div className='content-image'>
          <div className='image' onClick={() => (window.location.href = '/')} />
        </div>
        {enableNavigation && (
          <div className='content-info'>
            <div className='title'>Intuitiva</div>
            <Link className='container-menu' to='home' spy={true} smooth={true} offset={0} duration={500}>
              Home
            </Link>
            <Link className='container-menu' to='software' spy={true} smooth={true} offset={-200} duration={500}>
              Software
            </Link>
            <Link className='container-menu' to='hardware' spy={true} smooth={true} offset={-100} duration={500}>
              Hardware
            </Link>
            <Link className='container-menu' to='product' spy={true} smooth={true} offset={-100} duration={500}>
              Produtos
            </Link>
          </div>
        )}
        <div className='content-info'>
          <div className='title'>Termos Legais</div>
          <div className='container-menu' onClick={() => (window.location.href = '/termos-de-uso')}>
            Termos de Uso
          </div>
          <div className='container-menu' onClick={() => (window.location.href = '/politica-de-privacidade')}>
            Política de Privacidade
          </div>
        </div>
        <div className='content-info'>
          <div className='title'>Informações de Contato</div>
          <div className='container-menu'>Bahia, Vitoria da Conquista</div>
          <div className='container-menu'>+55 (77) 9988-2079</div>
          <div className='container-menu'>ronielli@intuitiva.io</div>
        </div>
      </div>
      <div className='line-division' />
      <div className='container-copy'>
        <div>Desenvolvido por Intuitiva</div>
        <div onClick={() => window.open('https://br.freepik.com')}>Designed by Freepik</div>
      </div>
    </div>
  );
};

export default Footer;
