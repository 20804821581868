import React from 'react';

import './model.scss';

export default function App() {
  const modelRef = React.useRef();

  return (
    <model-viewer
      ar
      ar-modes='webxr scene-viewer quick-look'
      camera-controls='false'
      shadow-intensity='1'
      src='./3d/caixaOee.glb'
      disable-tap
      disable-zoom
      environment-image='legacy'
      camera-orbit='-90deg -20deg'
      ref={(ref) => {
        modelRef.current = ref;
      }}
      style={{ height: '28rem', width: '100%' }}
    />
  );
}
