import React from 'react';
import { Element } from 'react-scroll';

import { BsFillFileBarGraphFill, BsFillGearFill, BsFillPuzzleFill } from 'react-icons/bs';
import { MdOutlineWifi } from 'react-icons/md';

import App from './components/model';

import './hardware.scss';

const Hardware: React.FC = () => {
  return (
    <Element name='hardware'>
      <div className='default-class-hardware'>
        <div className='container'>
          <div className='content-model'>
            <div className='model-3d'>
              <App />
            </div>
          </div>
          <div className='content-text'>
            <div className='title'>HARDWARE</div>
            <div className='description'>
              Na Intuitiva, estamos comprometidos em capacitar sua empresa com as ferramentas necessárias para alcançar
              a excelência operacional. Nosso hardware de coleta de dados é o coração da nossa abordagem inovadora,
              projetado para simplificar a aquisição de informações críticas de seus equipamentos industriais.
              <br />
              <br />
              Nossa paixão é impulsionar a eficiência em sua empresa. Com nosso hardware de coleta de dados de ponta,
              você terá o poder de tomar decisões informadas, otimizar seus processos e conquistar o sucesso
              sustentável.
              <br />
              <br />
              Acreditamos que a eficiência industrial começa com a captura precisa e automatizada de dados. Com o
              hardware de coleta de dados da Intuitiva, você pode aumentar a produtividade, reduzir custos e elevar sua
              empresa a um novo patamar de excelência operacional. Confie em nós para transformar dados em eficiência.
            </div>
          </div>
        </div>
        <div className='container-card'>
          <div className='card'>
            <div className='icon'>
              <BsFillGearFill />
            </div>
            <div className='title'>Automatização Inteligente</div>
            <div className='description'>
              Nossa solução automatiza a coleta de dados, garantindo precisão e confiabilidade.
            </div>
          </div>
          <div className='card'>
            <div className='icon'>
              <MdOutlineWifi />
            </div>
            <div className='title'>Conectividade Total</div>
            <div className='description'>
              Mantenha-se conectado a seus equipamentos, permitindo monitoramento em tempo real.
            </div>
          </div>
          <div className='card'>
            <div className='icon'>
              <BsFillPuzzleFill />
            </div>
            <div className='title'>Monitoramento Realtime</div>
            <div className='description'>
              Tenha uma visão atualizada do desempenho dos equipamentos para respostas rápidas.
            </div>
          </div>
          <div className='card'>
            <div className='icon'>
              <BsFillFileBarGraphFill />
            </div>
            <div className='title'>Facilidade de Implementação</div>
            <div className='description'>
              Nossa solução se integra facilmente, minimizando o tempo de inatividade e os custos.
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Hardware;
