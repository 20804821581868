import React from 'react';
import { Element } from 'react-scroll';
import imageContact from 'assets/freePik/Get in touch.gif';

import { getApi } from 'firebase/settings';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from 'primereact/messages';

import './contact.scss';

interface ISubject {
  code: string;
  name: string;
}

const Contact: React.FC = () => {
  const msgs = React.useRef<Messages>(null);

  const [subject, setSubject] = React.useState<ISubject | null>(null);
  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string | null | undefined>(null);
  const [message, setMessage] = React.useState<string>('');

  const cities = [
    { name: 'Dúvidas Gerais', code: 'NY' },
    { name: 'Consultoria', code: 'RM' },
    { name: 'Informações de Produto', code: 'LDN' },
    { name: 'Orçamento', code: 'IST' },
    { name: 'Outros', code: 'PRS' },
  ];

  async function addUser() {
    msgs?.current?.clear();

    const data = {
      name: name,
      email: email,
      telefone: phone,
      assunto: subject ? subject.name : '',
      message: 'string',
    };

    try {
      const api = getApi();
      const response = await api.post('/contact/create', data);
      if (response.data.id) {
        setSubject(null);
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');

        msgs?.current?.show([
          {
            severity: 'success',
            detail: 'Sua Mensagem foi Enviada com Sucesso!',
            closable: true,
            sticky: true,
          },
        ]);

        return true;
      }
      return false;
    } catch (err) {
      msgs?.current?.show([
        {
          severity: 'error',
          detail: 'Não foi possível Enviar sua Mensagem, Verifique as informações e tente novamente!',
          closable: true,
          sticky: true,
        },
      ]);
      return false;
    }
  }

  return (
    <Element name='contact'>
      <div className='default-class-contact'>
        <div className='container'>
          <div className='content-illustration'>
            <img className='image' src={imageContact} />
          </div>
          <div className='content-form'>
            <div className='title'>Contate-nos</div>
            <div className='subtitle'>
              Estamos prontos para ouvir você. Envie-nos uma mensagem e vamos começar a trabalhar juntos.
            </div>
            <Messages ref={msgs} />
            <span className='p-input-icon-left'>
              <i className='pi pi-user' />
              <InputText placeholder='Nome' className='input' value={name} onChange={(e) => setName(e.target.value)} />
            </span>
            <span className='p-input-icon-left'>
              <i className='pi pi-envelope' />
              <InputText
                placeholder='E-mail'
                type='email'
                className='input'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </span>
            <span className='p-input-icon-left'>
              <i className='pi pi-phone' />
              <InputText
                className='input'
                value={phone as string}
                placeholder='Telefone'
                onChange={(e) => setPhone(e.target.value)}
              />
            </span>
            <Dropdown
              optionLabel='name'
              placeholder='Assunto'
              value={subject}
              onChange={(e) => setSubject(e.value)}
              options={cities}
            />
            <InputTextarea
              rows={5}
              cols={15}
              placeholder='Mensagem'
              style={{ resize: 'none' }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              label='Enviar'
              icon='pi pi-send'
              iconPos='right'
              onClick={addUser}
              disabled={!name || !email || !phone || !subject || !message}
            />
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Contact;
