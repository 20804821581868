import React from 'react';
import { Helmet } from 'react-helmet';
import waveBottom from 'assets/waves/wave-bottom.svg';

import Footer from '../institucional/footer/footer';

import './termsOfUse.scss';

const TermsOfUse: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Intuitiva® - Termos de Uso</title>
        <link rel='canonical' href='http://intuitiva.io/termos-de-uso' />
      </Helmet>
      <div className='container-terms-of-use'>
        <div className='content'>
          <div className='header'>
            <div className='image' onClick={() => (window.location.href = '/')} />
          </div>
          <div className='container'>
            <div className='title'>Termos de Uso</div>
            <div className='subtitle'>Última atualização em 03/10/2023</div>
          </div>
        </div>
        <img src={waveBottom} className='image-wave-bottom-abs' />
      </div>
      <div className='container-text'>
        <div className='content'>
          <b>1. Termos</b>
          <p>
            Ao acessar ao site Intuitiva, concorda em cumprir estes termos de uso, todas as leis e regulamentos
            aplicáveis e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não
            concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste
            site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.
          </p>
          <b>2. Uso de Licença</b>
          <p>
            É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site
            Intuitiva , apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença,
            não uma transferência de título e, sob esta licença, você não pode:
          </p>
          <ol>
            <li>modificar ou copiar os materiais;</li>
            <li>
              usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não
              comercial);
            </li>
            <li>tentar descompilar ou fazer engenharia reversa de qualquer software contido no site Intuitiva;</li>
            <li>remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou</li>
            <li>
              transferir os materiais para outra pessoa ou &apos;espelhe&apos; os materiais em qualquer outro servidor.
            </li>
          </ol>
          <p>
            Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida
            por Intuitiva a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta
            licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrônico ou impresso.
          </p>
          <b>3. Isenção de responsabilidade</b>
          <ol>
            <li>
              Os materiais no site da Intuitiva são fornecidos &apos;como estão&apos;. Intuitiva não oferece garantias,
              expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem
              limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não
              violação de propriedade intelectual ou outra violação de direitos.
            </li>
            <li>
              Além disso, A Intuitiva não garante ou faz qualquer representação relativa à precisão, aos resultados
              prováveis ou à confiabilidade do uso dos materiais em seu site ou de outra forma relacionado a esses
              materiais ou em sites vinculados a este site.
            </li>
          </ol>
          <b>4. Limitações</b>
          <p>
            Em nenhum caso A Intuitiva ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem
            limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da
            incapacidade de usar os materiais em Intuitiva, mesmo que Intuitiva ou um representante autorizado da
            Intuitiva tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas
            jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos
            consequentes ou incidentais, essas limitações podem não se aplicar a você.
          </p>
          <b>5. Precisão dos materiais</b>
          <p>
            Os materiais exibidos no site da Intuitiva podem incluir erros técnicos, tipográficos ou fotográficos.
            Intuitiva não garante que qualquer material em seu site seja preciso, completo ou atual. Intuitiva pode
            fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto,
            Intuitiva não se compromete a atualizar os materiais.
          </p>
          <b>6. Links</b>
          <p>
            A Intuitiva não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum
            site vinculado. A inclusão de qualquer link não implica endosso por Intuitiva do site. O uso de qualquer
            site vinculado é por conta e risco do usuário.
          </p>
          <b>Modificações</b>
          <p>
            A Intuitiva pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este
            site, você concorda em ficar vinculado à versão atual desses termos de serviço.
          </p>
          <b>Lei aplicável</b>
          <p>
            Estes termos e condições são regidos e interpretados de acordo com as leis do Intuitiva e você se submete
            irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade.
          </p>
        </div>
      </div>
      <Footer enableNavigation={false} />
    </React.Fragment>
  );
};

export default TermsOfUse;
