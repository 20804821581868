import React from 'react';
import { animateScroll } from 'react-scroll';

import { FaAngleUp } from 'react-icons/fa6';

import './backToTop.scss';

const BackToTop: React.FC = () => {
  const [enableBackToTop, setEnableBackToTop] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        setEnableBackToTop(true);
      } else {
        setEnableBackToTop(false);
      }
    });
  }, []);

  return (
    <div className='back-to-top' style={{ opacity: enableBackToTop ? '1' : '0' }}>
      <div className='back-to-top-button' onClick={() => animateScroll.scrollToTop()}>
        <FaAngleUp />
      </div>
    </div>
  );
};

export default BackToTop;
