import React from 'react';

import Posts from './services/services';

import About from './about/about';
import Contact from './contact/contact';
import Footer from './footer/footer';
import Hardware from './hardware/hardware';
import Home from './home/home';
import Software from './software/software';

import './institucional.scss';

const Institucional: React.FC = () => {
  return (
    <React.Fragment>
      <Home />
      <About />
      <Software />
      <Hardware />
      <Posts />
      <Contact />
      <Footer enableNavigation={true} />
    </React.Fragment>
  );
};

export default Institucional;
