import React from 'react';

import { FaWhatsapp } from 'react-icons/fa';

import './contactWidget.scss';

const ContactWidget: React.FC = () => {
  return (
    <div className='contact-widget'>
      <a
        className='qlwapp-toggle'
        data-action='open'
        data-phone='557799882079'
        role='button'
        target='_blank'
        rel='noreferrer'
        href={
          'https://api.whatsapp.com/send?phone=557799882079&text=%0AOl%C3%A1%2C%20visitei%20o%20seu%20site%20e%20estou%20interessado%20em%20obter%20mais%20informa%C3%A7%C3%B5es.'
        }
      >
        <div className='contact-widget-message'>Dúvidas? Vamos Conversar!</div>
        <div className='contact-widget-button'>
          <FaWhatsapp />
        </div>
      </a>
    </div>
  );
};

export default ContactWidget;
