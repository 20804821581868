import React from 'react';
import { Element } from 'react-scroll';
import system from 'assets/intuitiva/system.webp';
import waveBottom from 'assets/waves/wave-bottom.svg';
import waveTop from 'assets/waves/wave-top.svg';

import './software.scss';

const Software: React.FC = () => {
  return (
    <Element name='software'>
      <div className='default-class-software'>
        <img src={waveTop} className='image-wave-top' />
        <div className='container'>
          <div className='content-text'>
            <div className='title'>NOSSO SOFTWARE</div>
            <div className='description'>
              Nosso software é a chave para otimizar suas operações industriais. Ele oferece análises avançadas que
              ajudam a identificar áreas de melhoria e impulsionar a eficiência. A interface intuitiva facilita o uso e
              a tomada de decisões informadas.
              <br />
              <br />
              Além disso, integra-se facilmente aos seus sistemas existentes, minimizando interrupções. Com suporte
              técnico dedicado, mantemos seu software atualizado para atender às necessidades em evolução da indústria.
              <br />
              <br />
              Confie em nosso software para manter sua empresa competitiva e no caminho para o sucesso contínuo.
            </div>
          </div>
          <div className='content-img'>
            <img className='image' src={system} />
          </div>
        </div>
        <img src={waveBottom} className='image-wave-bottom' />
      </div>
    </Element>
  );
};

export default Software;
