import React from 'react';

import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PrivacyPolicy from 'pages/privacyPolicy/privacyPolicy';
import TermsOfUse from 'pages/termsOfUse/termsOfUse';

import Institucional from './pages/institucional/institucional';

import BackToTop from 'components/backToTop';
import ContactWidget from 'components/contactWidget';

import reportWebVitals from 'services/reportWebVitals';

import 'primeicons/primeicons.css';
import 'themes/theme.css';
import 'primereact/resources/primereact.min.css';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/' element={<Institucional />} />
        <Route path='/termos-de-uso' element={<TermsOfUse />} />
        <Route path='/politica-de-privacidade' element={<PrivacyPolicy />} />
      </Routes>
      <ContactWidget />
      <BackToTop />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
